<template>
  <div>
    <div class="divier_top" />
    <div class="header" style="">
      <my-avatar :img="httpInfo.Head" :text="getIcon"></my-avatar>
    </div>

    <div class="info-list">

      <div class="info-item">
        <div class="info-item-title">{{ $t('center_address') }}</div>
        <div class="info-item-content">
          <div class="info-item-content-text">{{ account | strHidden(13, 5, 4) }}</div>
          <!-- <i class="info-item-content-icon el-icon-document-copy" v-clipboard:copy="account"
            v-clipboard:success="onCopy"></i> -->
          <img class="info-item-content-icon" src="../assets/img/mer_copy.png" v-clipboard:copy="account"
            v-clipboard:success="onCopy" />
        </div>
      </div>

      <div class="info-item">
        <div class="info-item-title">{{ $t('center_merchant_1') }}</div>
        <div class="info-item-content">
          <div class="info-item-content-text">{{ httpInfo.Nick }}</div>
          <!-- <i class="info-item-content-icon el-icon-edit" @click="modifyName"></i> -->
          <img class="info-item-content-icon" src="../assets/img/mer_modify.png" @click="modifyName" />
        </div>
      </div>
      <!-- mer_modify -->

      <div class="info-item">
        <div class="info-item-title">{{ $t('center_phone') }}</div>
        <div class="info-item-content">
          <div class="info-item-content-text">{{ httpInfo.Mobile }}</div>
          <!-- <i class="info-item-content-icon el-icon-edit" @click="modifyName"></i> -->
          <img class="info-item-content-icon" src="../assets/img/mer_modify.png" @click="modifyName" />
        </div>
      </div>

      <div class="info-item">
        <div class="info-item-title">{{ $t('center_bzj') }}</div>
        <div class="info-item-content">
          <div class="info-item-content-text">{{ info.bail.div(info.usdtDecimals) }}USDT</div>
        </div>
      </div>

    </div>



    <div class="container-flex-h-center" style="padding-left: 16px;padding-right:16px;margin-top: 32px;">
      <div @click="orderFinish" class="status_container_item_left">
        <div class="container-flex-h-center">
          <div class="title_data">
            <img class="status_cls" src="../assets/img/mer_status_deal.png" />
            <div>{{ $t("merchant-center-finish") }}</div>
          </div>
          <img class="right_icon" src="../assets/img/right.png" />
        </div>

        <div class="text_data">{{ httpInfo.DealOrder ?? 0 }} {{ $t("merchant-center-unit") }}
        </div>
      </div>

      <div @click="orderIng" class="status_container_item">
        <div class="container-flex-h-center">

          <div class="title_data">
            <img class="status_cls" src="../assets/img/mer_status_warn.png" />
            {{ $t("merchant-center-ing") }}
          </div>
          <img class="right_icon" src="../assets/img/right.png" />
        </div>

        <div class="text_data">{{ httpInfo.LoadOrder ?? 0 }} {{ $t("merchant-center-unit") }}
        </div>
      </div>
    </div>


    <div class="container-flex-h-center status_container">
      <div @click="orderError" class="status_container_item_left">
        <div class="container-flex-h-center">
          <div class="title_data">
            <img class="status_cls" src="../assets/img/mer_status_ing.png" />
            {{ $t("merchant-center-error") }}
          </div>
          <img class="right_icon" src="../assets/img/right.png" />
        </div>

        <div class="text_data">{{ httpInfo.ErrorOrder }} {{ $t('merchant-center-unit') }}
        </div>
      </div>

      <div @click="orderSuccessRate" class="status_container_item">
        <div class="container-flex-h-center">
          <div class="title_data">
            <img class="status_cls" src="../assets/img/mer_status_rate.png" />
            {{ $t("merchant-center-success-rate") }}
          </div>
          <img class="right_icon" src="../assets/img/right.png" />
        </div>
        <div class="text_data">{{ rate }}%
        </div>
      </div>
    </div>


    <div class="btn_container">
      <el-button class="btn_left" @click="unregister" :loading="unregisterLoding">{{ $t('unregister') }}
      </el-button>
    </div>


    <van-dialog :confirmButtonText="$t('confirm')" :cancelButtonText="$t('cancel')" class="dialog" v-model="nameShow"
      :title="$t('modify_name')" show-cancel-button @confirm="upName">
      <!--输入框-->
      <van-field style="padding: 30px" v-model="name" rows="1" autosize type="text" :placeholder="$t('input_name')" />
    </van-dialog>

    <van-dialog :confirmButtonText="$t('confirm')" :cancelButtonText="$t('cancel')" class="dialog" v-model="telShow"
      :title="$t('modify_phone')" show-cancel-button @confirm="upTel">
      <!--输入框-->
      <van-field style="padding: 30px" v-model="tel" rows="1" autosize type="text" :placeholder="$t('input_name')" />
    </van-dialog>

  </div>
</template>
<script>
import { getAccounts } from "../utils/wallet";
import { CenterInfo, TraderUpdateData } from "../api/home";
import BigNumber from "bignumber.js";
import { MerchantInfo, MerchantUpdateInfo } from "../api/httpApi";
import MyAvatar from "../components/my-avatar"
import { mapGetters } from "vuex";

export default {
  name: "MerchantCenter",
  components: {
    MyAvatar
  },
  computed: {
    ...mapGetters(["protocol"]),
    rate() {
      let sum = this.httpInfo.TotalOrder
      if (sum <= 0) {
        return 0.00
      }
      return new BigNumber(this.httpInfo.DealOrder).div(new BigNumber(sum)).times(new BigNumber(100)).decimalPlaces(2)
    },
    getIcon() {
      return this.httpInfo.Nick.substring(0, 1)
    },
  },
  data() {
    return {
      unregisterLoding: false,
      updateLoading: false,
      name: "",
      nameShow: false,
      tel: "",
      telShow: false,
      account: "",
      infoLoading: false,
      info: {
        freeze: false,
        validation: false,
        status: false,
        name: "",
        tel: "",
        bail: new BigNumber(0),
        usdtDecimals: new BigNumber(1e18),
      },
      httpInfo: {
        Address: "",
        Nick: "",
        Head: "",
        Mobile: "",
        DealOrder: 0,
        ErrorOrder: 0,
        TotalOrder: 0,
        RevokeOrder: 0
      }
    }
  },
  created() {
    this.account = this.$route.query.account || ""
    this.getAuthorizeInfo().then()
  },
  methods: {
    unregister() {
      console.dir("注销")
    },

    onCopy(e) {
      this.$message.success(this.$t('copy_success'))
    },
    async getAuthorizeInfo() {
      if (this.account.length === 0) {
        const accounts = await getAccounts()
        this.account = accounts[0]
      }
      this.getHomeInfo().then()
      this.getHttpInfo()
    },
    async getHomeInfo() {
      if (this.infoLoading) {
        return false
      }
      this.infoLoading = true
      this.info = await CenterInfo(this.account)
      this.infoLoading = false
    },
    getHttpInfo() {
      const data = {
        Address: this.account,
        Protocol: this.protocol,
      }
      MerchantInfo(data)
        .then(res => {
          if (res.status !== 1) {
            return false
          }
          this.httpInfo = res.data
        })
    },
    modifyName() {
      this.nameShow = !this.nameShow
    },
    async upName() {
      if (this.updateLoading) {
        return false
      }
      if (this.name.length === 0) {
        return false
      }
      const _data = {
        name: this.name,
        tel: this.info.tel,
      }
      this.updateLoading = true
      this.$toast.loading({
        message: this.$t('modifying'),
        forbidClick: true,
        duration: 0,
        loadingType: 'spinner',
      })
      const res = await TraderUpdateData(_data)
      this.updateLoading = false
      this.$toast.clear()
      if (res === false) {
        this.$notify({
          type: 'warning',
          message: this.$t('center_approve_cancel'),
          duration: 2000,
          position: 'top-left',
        });
        return
      }
      this.$notify({
        type: 'success',
        message: this.$t('modify_success'),
        duration: 2000,
        position: 'top-left',
      });
      const hash = res.transactionHash
      MerchantUpdateInfo({
        Hash: hash,
        Protocol: this.protocol
      }).then(() => {
        this.info.name = this.name
      })
    },
    modifyTel() {
      this.telShow = !this.telShow
    },
    async upTel() {
      if (this.updateLoading) {
        return false
      }
      if (this.tel.length === 0) {
        return false
      }
      const _data = {
        name: this.info.name,
        tel: this.tel,
      }
      this.updateLoading = true
      this.$toast.loading({
        message: this.$t('modifying'),
        forbidClick: true,
        duration: 0,
        loadingType: 'spinner',
      })
      const res = await TraderUpdateData(_data)
      this.updateLoading = false
      this.$toast.clear()
      if (res === false) {
        this.$notify({
          type: 'warning',
          message: this.$t('center_approve_cancel'),
          duration: 2000,
          position: 'top-left',
        });
        return
      }
      this.$notify({
        type: 'success',
        message: this.$t('modify_success'),
        duration: 2000,
        position: 'top-left',
      });
      const hash = res.transactionHash
      MerchantUpdateInfo({
        Hash: hash,
        Protocol: this.protocol
      }).then(() => {
        this.httpInfo.Mobile = this.tel
      })
    },
    orderFinish() {
      console.dir("orderFinish")
    },
    orderIng() {
      console.dir("orderIng")
    },
    orderError() {
      console.dir("orderError")
    },
    orderSuccessRate() {
      console.dir("orderSuccessRate")
    }
  }
}
</script>

<style scoped>
.btn_left {
  height: 80px;
  flex: 1;
  border: 2px solid #EC8080;
  border-radius: 10px;
  font-size: 32px;
  margin-top: 60px;
  font-weight: 400;
  color: #EC8080;
}

.btn_container {
  padding: 70px;
  display: flex;
}

.status_container {
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 30px;
}

.status_container_item {
  background: #F0FAFD;
  width: 100%;
  border-radius: 20px;
  padding: 28px;
  margin-left: 30px;
}

.status_container_item_left {
  background: #F0FAFD;
  width: 100%;
  border-radius: 20px;
  padding: 28px;
}


.dialog {
  width: 65%;
}

.info-list {
  padding: 24px 40px 0 40px;
}

.info-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 48px;
}

.divier_top {
  background-color: #F5F5F5;
  height: 10px;
}

.title_data {
  font-size: 24px;
  font-weight: bold;
  color: #9F9F9F;
  display: flex;
  align-items: center;
}

.text_data {
  font-size: 32px;
  font-weight: bold;
  color: #333333;
  margin-top: 12px;
}

.info-item-title {
  font-size: 24px;
  font-weight: 500;
  color: #666666;
}

.info-item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.info-item-content-text {
  font-size: 24px;
  color: #999999;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 400px;
}

.info-item-content-icon {
  margin-left: 10px;
  width: 22px;
  height: 22px;
}

.header {
  display: flex;
  justify-content: center;
  margin-top: 72px;
}

.right_icon {
  width: 12px;
}

.status_cls {
  height: 24px;
  margin-right: 10px;
}
</style>